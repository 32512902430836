import React, { useState, useEffect, useContext } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import 'pure-react-carousel/dist/react-carousel.es.css'
import SEO from '~/components/seo'
import {
  ProductsWrapper,
  ProductTypeTitle,
  Product,
  Title,
  PriceTag,
  Img,
  ProductsDescription,
  ColorsDescription,
  ProductTypeTitleMobile,
  ProductTypeTitleMobileOther,
  ProductsWrapperMobile,
  ProductTypeTitleOther,
} from './styles'
import { BrowserView, MobileView } from 'react-device-detect'
import { GeneralGrid, GeneralGridMobile } from '~/utils/styles'
import Navigation from '../../components/Navigation'
import { changeName } from './changeName'
import StoreContext from '~/context/StoreContext'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import './effect.css'

const countQuantity = lineItems => {
  let quantity = 0

  lineItems.forEach(item => {
    quantity = quantity + item.quantity
  })

  return quantity
}

const WomenTypes = [
  'women_outerwear',
  'women_jackets_coats',
  'women_tops',
  'women_tshirts',
  'women_hoodie',
  'women_knitwear',
  'women_crewneck',
  'women_pants',
  'women_denim',
  'women_dresses',
  'women_shoes',
  'women_accessories',
  'women_face_mask',
]

const MenTypes = [
  'men_jackets',
  'men_pants',
  'men_tshirts',
  'men_shoes',
  'men_accessories',
  'men_face_mask',
]

const ProductType = ({ data }) => {
  const {
    setShowTypes,
    store: { checkout, showProductTypes, subscription },
  } = useContext(StoreContext)

  const [currentProductType, setCurrentProductType] = useState('')
  const [pathType, setPath] = useState('')
  const [colorCircles, showColorCircles] = useState()
  const [productDisplay, setProductDisplay] = useState()
  const [womenCategories, setWomenCategories] = useState([])
  const [menCategories, setMenCategories] = useState([])

  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(sort: { fields: [createdAt], order: DESC }) {
          edges {
            node {
              id
              title
              handle
              productType
              createdAt
              shopifyId
              options {
                id
                name
                values
              }
              images {
                id
                originalSrc
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 910) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              variants {
                price
              }
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    const pathArray = window.location.pathname.split('/')
    const BeforeSortingWomen = []
    const BeforeSortingMen = []

    allShopifyProduct.edges.map(({ node: { productType } }) => {
      if (productType.substring(0, 3) === 'wom') {
        BeforeSortingWomen.push(productType)
      } else if (productType.substring(0, 3) === 'men') {
        const BeforeSortingMen = []
        BeforeSortingMen.push(productType)
      }
    })

    const WomenSorted = BeforeSortingWomen.filter(function(item, pos) {
      return BeforeSortingWomen.indexOf(item) == pos
    })
    setWomenCategories(WomenSorted)

    const MenSorted = BeforeSortingMen.filter(function(item, pos) {
      return BeforeSortingMen.indexOf(item) == pos
    })
    setMenCategories(MenSorted)

    if (pathArray[2].substring(0, 3) === 'wom') {
      const thePosition = WomenSorted.indexOf(pathArray[2])
      const calc = WomenSorted.length - thePosition
      const ShowingProductTypes = WomenSorted.slice(
        thePosition,
        thePosition + calc
      )
      setShowTypes(ShowingProductTypes)
      setProductDisplay(ShowingProductTypes)
    } else if (pathArray[2].substring(0, 3) === 'men') {
      const thePosition = MenSorted.indexOf(pathArray[2])
      const calc = MenSorted.length - thePosition
      const ShowingProductTypes = MenSorted.slice(
        thePosition,
        thePosition + calc
      )
      setShowTypes(ShowingProductTypes)
      setProductDisplay(ShowingProductTypes)
    }
  }, [])

  useEffect(() => {
    const pathArray = window.location.pathname.split('/')
    setPath(pathArray[2])
    const nameFromUrl = changeName(pathArray[2])
    setCurrentProductType(nameFromUrl)
  }, [])

  const getPrice = price =>
    Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
    }).format(parseFloat(price ? price : 0))

  useEffect(() => {}, [productDisplay])

  return (
    <>
      <SEO
        // title={title}
        // description={description}
        keywords={[
          `bureau de Stil`,
          `ben tavertini`,
          `kim kardashian`,
          'LA Fashion',
          'fashion',
          'joyce bonelli',
          'unravel project',
          'trend brand',
          'online shop',
        ]}
      />
      {pathType ? (
        <div>
          <BrowserView>
            <GeneralGrid
              style={{
                height: subscription ? '80vh' : null,
              }}
            >
              <Navigation />
              <ProductTypeTitle className="fade-in">
                {currentProductType}
              </ProductTypeTitle>
              <ProductsWrapper
                style={{
                  filter: subscription ? 'opacity(0.2)' : null,
                }}
                className="fade-in"
              >
                {allShopifyProduct.edges
                  ? allShopifyProduct.edges.map(
                      ({
                        node: {
                          id,
                          handle,
                          title,
                          productType,
                          images: [firstImage],
                          variants: [firstVariant],
                          options,
                        },
                      }) => {
                        if (productType === pathType) {
                          return (
                            <Product
                              onMouseOver={() => showColorCircles(id)}
                              onMouseLeave={() => showColorCircles('')}
                              onClick={e => {
                                // To stop the page reloading
                                e.preventDefault()
                                // Lets track that custom click
                                trackCustomEvent({
                                  // string - required - The object that was interacted with (e.g.video)
                                  category: 'Desktop | Click on Product',
                                  // string - required - Type of interaction (e.g. 'play')
                                  action: 'Click',
                                  // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                  label: 'F/W 2020 Shop',
                                  // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                  value: handle,
                                })
                              }}
                            >
                              <Link
                                style={{ textDecoration: 'none' }}
                                to={`/product/${handle}/`}
                              >
                                {firstImage && firstImage.localFile && (
                                  <Img
                                    style={{ objectFit: 'contain' }}
                                    fluid={
                                      firstImage.localFile.childImageSharp.fluid
                                    }
                                    alt={handle}
                                  />
                                )}
                                <ProductsDescription>
                                  <Title>{title}</Title>
                                  <PriceTag>
                                    USD${getPrice(firstVariant.price)}
                                  </PriceTag>
                                </ProductsDescription>
                                <ColorsDescription>
                                  {options[1] !== undefined
                                    ? options[1].values.map(item => (
                                        <div
                                          className="hover-effect"
                                          style={{
                                            visibility:
                                              colorCircles == id
                                                ? 'visible'
                                                : 'hidden',
                                            background: '#' + item,

                                            borderRadius: '50%',
                                            width: '12px',
                                            height: '12px',
                                            margin: '0 10px 0 0',
                                            border: '0.5px solid black',
                                          }}
                                        ></div>
                                      ))
                                    : null}
                                </ColorsDescription>
                              </Link>
                            </Product>
                          )
                        }
                      }
                    )
                  : null}
              </ProductsWrapper>

              {productDisplay[1] ? (
                <ProductTypeTitleOther>
                  {changeName(productDisplay[1])}
                </ProductTypeTitleOther>
              ) : null}

              {productDisplay[1] ? (
                <ProductsWrapper
                  style={{
                    filter: subscription ? 'opacity(0.2)' : null,
                  }}
                  className="fade-in"
                >
                  {allShopifyProduct.edges
                    ? allShopifyProduct.edges.map(
                        ({
                          node: {
                            id,
                            handle,
                            title,
                            productType,
                            images: [firstImage],
                            variants: [firstVariant],
                            options,
                          },
                        }) => {
                          if (productType === productDisplay[1]) {
                            return (
                              <Product
                                onMouseOver={() => showColorCircles(id)}
                                onMouseLeave={() => showColorCircles('')}
                                key={id}
                                onClick={e => {
                                  // To stop the page reloading
                                  e.preventDefault()
                                  // Lets track that custom click
                                  trackCustomEvent({
                                    // string - required - The object that was interacted with (e.g.video)
                                    category: 'Desktop | Click on Product',
                                    // string - required - Type of interaction (e.g. 'play')
                                    action: 'Click',
                                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                    label: 'F/W 2020 Shop',
                                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                    value: handle,
                                  })
                                }}
                              >
                                <Link
                                  style={{ textDecoration: 'none' }}
                                  to={`/product/${handle}/`}
                                >
                                  {firstImage && firstImage.localFile && (
                                    <Img
                                      style={{ objectFit: 'contain' }}
                                      fluid={
                                        firstImage.localFile.childImageSharp
                                          .fluid
                                      }
                                      alt={handle}
                                    />
                                  )}
                                  <ProductsDescription>
                                    <Title>{title}</Title>
                                    <PriceTag>
                                      USD${getPrice(firstVariant.price)}
                                    </PriceTag>
                                  </ProductsDescription>
                                  <ColorsDescription>
                                    {options[1] !== undefined
                                      ? options[1].values.map(item => (
                                          <div
                                            className="hover-effect"
                                            style={{
                                              visibility:
                                                colorCircles == id
                                                  ? 'visible'
                                                  : 'hidden',
                                              background: '#' + item,

                                              borderRadius: '50%',
                                              width: '12px',
                                              height: '12px',
                                              margin: '0 10px 0 0',
                                              border: '0.5px solid black',
                                            }}
                                          ></div>
                                        ))
                                      : null}
                                  </ColorsDescription>
                                </Link>
                              </Product>
                            )
                          }
                        }
                      )
                    : null}
                </ProductsWrapper>
              ) : null}

              {productDisplay[2] ? (
                <ProductTypeTitleOther>
                  {changeName(productDisplay[2])}
                </ProductTypeTitleOther>
              ) : null}

              {productDisplay[2] ? (
                <ProductsWrapper
                  style={{
                    filter: subscription ? 'opacity(0.2)' : null,
                  }}
                  className="fade-in"
                >
                  {allShopifyProduct.edges
                    ? allShopifyProduct.edges.map(
                        ({
                          node: {
                            id,
                            handle,
                            title,
                            productType,
                            images: [firstImage],
                            variants: [firstVariant],
                            options,
                          },
                        }) => {
                          if (productType === productDisplay[2]) {
                            return (
                              <Product
                                onMouseOver={() => showColorCircles(id)}
                                onMouseLeave={() => showColorCircles('')}
                                key={id}
                                onClick={e => {
                                  // To stop the page reloading
                                  e.preventDefault()
                                  // Lets track that custom click
                                  trackCustomEvent({
                                    // string - required - The object that was interacted with (e.g.video)
                                    category: 'Desktop | Click on Product',
                                    // string - required - Type of interaction (e.g. 'play')
                                    action: 'Click',
                                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                    label: 'F/W 2020 Shop',
                                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                    value: handle,
                                  })
                                }}
                              >
                                <Link
                                  style={{ textDecoration: 'none' }}
                                  to={`/product/${handle}/`}
                                >
                                  {firstImage && firstImage.localFile && (
                                    <Img
                                      style={{ objectFit: 'contain' }}
                                      fluid={
                                        firstImage.localFile.childImageSharp
                                          .fluid
                                      }
                                      alt={handle}
                                    />
                                  )}
                                  <ProductsDescription>
                                    <Title>{title}</Title>
                                    <PriceTag>
                                      USD${getPrice(firstVariant.price)}
                                    </PriceTag>
                                  </ProductsDescription>
                                  <ColorsDescription>
                                    {options[1] !== undefined
                                      ? options[1].values.map(item => (
                                          <div
                                            className="hover-effect"
                                            style={{
                                              visibility:
                                                colorCircles == id
                                                  ? 'visible'
                                                  : 'hidden',
                                              background: '#' + item,

                                              borderRadius: '50%',
                                              width: '12px',
                                              height: '12px',
                                              margin: '0 10px 0 0',
                                              border: '0.5px solid black',
                                            }}
                                          ></div>
                                        ))
                                      : null}
                                  </ColorsDescription>
                                </Link>
                              </Product>
                            )
                          }
                        }
                      )
                    : null}
                </ProductsWrapper>
              ) : null}

              {productDisplay[3] ? (
                <ProductTypeTitleOther>
                  {changeName(productDisplay[3])}
                </ProductTypeTitleOther>
              ) : null}

              {productDisplay[3] ? (
                <ProductsWrapper
                  style={{
                    filter: subscription ? 'opacity(0.2)' : null,
                  }}
                  className="fade-in"
                >
                  {allShopifyProduct.edges
                    ? allShopifyProduct.edges.map(
                        ({
                          node: {
                            id,
                            handle,
                            title,
                            productType,
                            images: [firstImage],
                            variants: [firstVariant],
                            options,
                          },
                        }) => {
                          if (productType === productDisplay[3]) {
                            return (
                              <Product
                                onMouseOver={() => showColorCircles(id)}
                                onMouseLeave={() => showColorCircles('')}
                                key={id}
                                onClick={e => {
                                  // To stop the page reloading
                                  e.preventDefault()
                                  // Lets track that custom click
                                  trackCustomEvent({
                                    // string - required - The object that was interacted with (e.g.video)
                                    category: 'Desktop | Click on Product',
                                    // string - required - Type of interaction (e.g. 'play')
                                    action: 'Click',
                                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                    label: 'F/W 2020 Shop',
                                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                    value: handle,
                                  })
                                }}
                              >
                                <Link
                                  style={{ textDecoration: 'none' }}
                                  to={`/product/${handle}/`}
                                >
                                  {firstImage && firstImage.localFile && (
                                    <Img
                                      style={{ objectFit: 'contain' }}
                                      fluid={
                                        firstImage.localFile.childImageSharp
                                          .fluid
                                      }
                                      alt={handle}
                                    />
                                  )}
                                  <ProductsDescription>
                                    <Title>{title}</Title>
                                    <PriceTag>
                                      USD${getPrice(firstVariant.price)}
                                    </PriceTag>
                                  </ProductsDescription>
                                  <ColorsDescription>
                                    {options[1] !== undefined
                                      ? options[1].values.map(item => (
                                          <div
                                            className="hover-effect"
                                            style={{
                                              visibility:
                                                colorCircles == id
                                                  ? 'visible'
                                                  : 'hidden',
                                              background: '#' + item,

                                              borderRadius: '50%',
                                              width: '12px',
                                              height: '12px',
                                              margin: '0 10px 0 0',
                                              border: '0.5px solid black',
                                            }}
                                          ></div>
                                        ))
                                      : null}
                                  </ColorsDescription>
                                </Link>
                              </Product>
                            )
                          }
                        }
                      )
                    : null}
                </ProductsWrapper>
              ) : null}

              {productDisplay[4] ? (
                <ProductTypeTitleOther>
                  {changeName(productDisplay[4])}
                </ProductTypeTitleOther>
              ) : null}

              {productDisplay[4] ? (
                <ProductsWrapper
                  style={{
                    filter: subscription ? 'opacity(0.2)' : null,
                  }}
                  className="fade-in"
                >
                  {allShopifyProduct.edges
                    ? allShopifyProduct.edges.map(
                        ({
                          node: {
                            id,
                            handle,
                            title,
                            productType,
                            images: [firstImage],
                            variants: [firstVariant],
                            options,
                          },
                        }) => {
                          if (productType === productDisplay[4]) {
                            return (
                              <Product
                                onMouseOver={() => showColorCircles(id)}
                                onMouseLeave={() => showColorCircles('')}
                                key={id}
                                onClick={e => {
                                  // To stop the page reloading
                                  e.preventDefault()
                                  // Lets track that custom click
                                  trackCustomEvent({
                                    // string - required - The object that was interacted with (e.g.video)
                                    category: 'Desktop | Click on Product',
                                    // string - required - Type of interaction (e.g. 'play')
                                    action: 'Click',
                                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                    label: 'F/W 2020 Shop',
                                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                    value: handle,
                                  })
                                }}
                              >
                                <Link
                                  style={{ textDecoration: 'none' }}
                                  to={`/product/${handle}/`}
                                >
                                  {firstImage && firstImage.localFile && (
                                    <Img
                                      style={{ objectFit: 'contain' }}
                                      fluid={
                                        firstImage.localFile.childImageSharp
                                          .fluid
                                      }
                                      alt={handle}
                                    />
                                  )}
                                  <ProductsDescription>
                                    <Title>{title}</Title>
                                    <PriceTag>
                                      USD${getPrice(firstVariant.price)}
                                    </PriceTag>
                                  </ProductsDescription>
                                  <ColorsDescription>
                                    {options[1] !== undefined
                                      ? options[1].values.map(item => (
                                          <div
                                            className="hover-effect"
                                            style={{
                                              visibility:
                                                colorCircles == id
                                                  ? 'visible'
                                                  : 'hidden',
                                              background: '#' + item,

                                              borderRadius: '50%',
                                              width: '12px',
                                              height: '12px',
                                              margin: '0 10px 0 0',
                                              border: '0.5px solid black',
                                            }}
                                          ></div>
                                        ))
                                      : null}
                                  </ColorsDescription>
                                </Link>
                              </Product>
                            )
                          }
                        }
                      )
                    : null}
                </ProductsWrapper>
              ) : null}

              {productDisplay[5] ? (
                <ProductTypeTitleOther>
                  {changeName(productDisplay[5])}
                </ProductTypeTitleOther>
              ) : null}

              {productDisplay[5] ? (
                <ProductsWrapper
                  style={{
                    filter: subscription ? 'opacity(0.2)' : null,
                  }}
                  className="fade-in"
                >
                  {allShopifyProduct.edges
                    ? allShopifyProduct.edges.map(
                        ({
                          node: {
                            id,
                            handle,
                            title,
                            productType,
                            images: [firstImage],
                            variants: [firstVariant],
                            options,
                          },
                        }) => {
                          if (productType === productDisplay[5]) {
                            return (
                              <Product
                                onMouseOver={() => showColorCircles(id)}
                                onMouseLeave={() => showColorCircles('')}
                                key={id}
                                onClick={e => {
                                  // To stop the page reloading
                                  e.preventDefault()
                                  // Lets track that custom click
                                  trackCustomEvent({
                                    // string - required - The object that was interacted with (e.g.video)
                                    category: 'Desktop | Click on Product',
                                    // string - required - Type of interaction (e.g. 'play')
                                    action: 'Click',
                                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                    label: 'F/W 2020 Shop',
                                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                    value: handle,
                                  })
                                }}
                              >
                                <Link
                                  style={{ textDecoration: 'none' }}
                                  to={`/product/${handle}/`}
                                >
                                  {firstImage && firstImage.localFile && (
                                    <Img
                                      style={{ objectFit: 'contain' }}
                                      fluid={
                                        firstImage.localFile.childImageSharp
                                          .fluid
                                      }
                                      alt={handle}
                                    />
                                  )}
                                  <ProductsDescription>
                                    <Title>{title}</Title>
                                    <PriceTag>
                                      USD${getPrice(firstVariant.price)}
                                    </PriceTag>
                                  </ProductsDescription>
                                  <ColorsDescription>
                                    {options[1] !== undefined
                                      ? options[1].values.map(item => (
                                          <div
                                            className="hover-effect"
                                            style={{
                                              visibility:
                                                colorCircles == id
                                                  ? 'visible'
                                                  : 'hidden',
                                              background: '#' + item,

                                              borderRadius: '50%',
                                              width: '12px',
                                              height: '12px',
                                              margin: '0 10px 0 0',
                                              border: '0.5px solid black',
                                            }}
                                          ></div>
                                        ))
                                      : null}
                                  </ColorsDescription>
                                </Link>
                              </Product>
                            )
                          }
                        }
                      )
                    : null}
                </ProductsWrapper>
              ) : null}

              {productDisplay[6] ? (
                <ProductTypeTitleOther>
                  {changeName(productDisplay[6])}
                </ProductTypeTitleOther>
              ) : null}

              {productDisplay[6] ? (
                <ProductsWrapper
                  style={{
                    filter: subscription ? 'opacity(0.2)' : null,
                  }}
                  className="fade-in"
                >
                  {allShopifyProduct.edges
                    ? allShopifyProduct.edges.map(
                        ({
                          node: {
                            id,
                            handle,
                            title,
                            productType,
                            images: [firstImage],
                            variants: [firstVariant],
                            options,
                          },
                        }) => {
                          if (productType === productDisplay[6]) {
                            return (
                              <Product
                                onMouseOver={() => showColorCircles(id)}
                                onMouseLeave={() => showColorCircles('')}
                                key={id}
                                onClick={e => {
                                  // To stop the page reloading
                                  e.preventDefault()
                                  // Lets track that custom click
                                  trackCustomEvent({
                                    // string - required - The object that was interacted with (e.g.video)
                                    category: 'Desktop | Click on Product',
                                    // string - required - Type of interaction (e.g. 'play')
                                    action: 'Click',
                                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                    label: 'F/W 2020 Shop',
                                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                    value: handle,
                                  })
                                }}
                              >
                                <Link
                                  style={{ textDecoration: 'none' }}
                                  to={`/product/${handle}/`}
                                >
                                  {firstImage && firstImage.localFile && (
                                    <Img
                                      style={{ objectFit: 'contain' }}
                                      fluid={
                                        firstImage.localFile.childImageSharp
                                          .fluid
                                      }
                                      alt={handle}
                                    />
                                  )}
                                  <ProductsDescription>
                                    <Title>{title}</Title>
                                    <PriceTag>
                                      USD${getPrice(firstVariant.price)}
                                    </PriceTag>
                                  </ProductsDescription>
                                  <ColorsDescription>
                                    {options[1] !== undefined
                                      ? options[1].values.map(item => (
                                          <div
                                            className="hover-effect"
                                            style={{
                                              visibility:
                                                colorCircles == id
                                                  ? 'visible'
                                                  : 'hidden',
                                              background: '#' + item,

                                              borderRadius: '50%',
                                              width: '12px',
                                              height: '12px',
                                              margin: '0 10px 0 0',
                                              border: '0.5px solid black',
                                            }}
                                          ></div>
                                        ))
                                      : null}
                                  </ColorsDescription>
                                </Link>
                              </Product>
                            )
                          }
                        }
                      )
                    : null}
                </ProductsWrapper>
              ) : null}

              {productDisplay[7] ? (
                <ProductTypeTitleOther>
                  {changeName(productDisplay[7])}
                </ProductTypeTitleOther>
              ) : null}

              {productDisplay[7] ? (
                <ProductsWrapper>
                  {allShopifyProduct.edges
                    ? allShopifyProduct.edges.map(
                        ({
                          node: {
                            id,
                            handle,
                            title,
                            productType,
                            images: [firstImage],
                            variants: [firstVariant],
                            options,
                          },
                        }) => {
                          if (productType === productDisplay[7]) {
                            return (
                              <Product
                                onMouseOver={() => showColorCircles(id)}
                                onMouseLeave={() => showColorCircles('')}
                                key={id}
                                onClick={e => {
                                  // To stop the page reloading
                                  e.preventDefault()
                                  // Lets track that custom click
                                  trackCustomEvent({
                                    // string - required - The object that was interacted with (e.g.video)
                                    category: 'Desktop | Click on Product',
                                    // string - required - Type of interaction (e.g. 'play')
                                    action: 'Click',
                                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                    label: 'F/W 2020 Shop',
                                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                    value: handle,
                                  })
                                }}
                              >
                                <Link
                                  style={{ textDecoration: 'none' }}
                                  to={`/product/${handle}/`}
                                >
                                  {firstImage && firstImage.localFile && (
                                    <Img
                                      style={{ objectFit: 'contain' }}
                                      fluid={
                                        firstImage.localFile.childImageSharp
                                          .fluid
                                      }
                                      alt={handle}
                                    />
                                  )}
                                  <ProductsDescription>
                                    <Title>{title}</Title>
                                    <PriceTag>
                                      USD${getPrice(firstVariant.price)}
                                    </PriceTag>
                                  </ProductsDescription>
                                  <ColorsDescription>
                                    {options[1] !== undefined
                                      ? options[1].values.map(item => (
                                          <div
                                            className="hover-effect"
                                            style={{
                                              visibility:
                                                colorCircles == id
                                                  ? 'visible'
                                                  : 'hidden',
                                              background: '#' + item,

                                              borderRadius: '50%',
                                              width: '12px',
                                              height: '12px',
                                              margin: '0 10px 0 0',
                                              border: '0.5px solid black',
                                            }}
                                          ></div>
                                        ))
                                      : null}
                                  </ColorsDescription>
                                </Link>
                              </Product>
                            )
                          }
                        }
                      )
                    : null}
                </ProductsWrapper>
              ) : null}
            </GeneralGrid>
          </BrowserView>

          <MobileView>
            <Navigation />
            <ProductTypeTitleMobileOther className="fade-in">
              {currentProductType}
            </ProductTypeTitleMobileOther>
            <GeneralGridMobile className="fade-in">
              <ProductsWrapperMobile>
                {allShopifyProduct.edges
                  ? allShopifyProduct.edges.map(
                      ({
                        node: {
                          id,
                          handle,
                          title,
                          productType,
                          images: [firstImage],
                          variants: [firstVariant],
                          options,
                        },
                      }) => {
                        if (productType === pathType) {
                          return (
                            <Product
                              key={id}
                              onClick={e => {
                                // To stop the page reloading
                                e.preventDefault()
                                // Lets track that custom click
                                trackCustomEvent({
                                  // string - required - The object that was interacted with (e.g.video)
                                  category: 'Mobile | Click on Product',
                                  // string - required - Type of interaction (e.g. 'play')
                                  action: 'Click',
                                  // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                  label: 'F/W 2020 Shop',
                                  // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                  value: handle,
                                })
                              }}
                            >
                              <Link to={`/product/${handle}/`}>
                                {firstImage && firstImage.localFile && (
                                  <Img
                                    style={{ objectFit: 'contain' }}
                                    fluid={
                                      firstImage.localFile.childImageSharp.fluid
                                    }
                                    alt={handle}
                                  />
                                )}
                              </Link>
                              <ProductsDescription>
                                <Title>{title}</Title>
                                <PriceTag>
                                  USD${getPrice(firstVariant.price)}
                                </PriceTag>
                              </ProductsDescription>
                              <ColorsDescription>
                                {options[1] !== undefined
                                  ? options[1].values.map(item => (
                                      <div
                                        className="hover-effect"
                                        style={{
                                          background: '#' + item,

                                          borderRadius: '50%',
                                          width: '18px',
                                          height: '18px',
                                          margin: '0 10px 0 0',
                                          border: '0.5px solid black',
                                        }}
                                      ></div>
                                    ))
                                  : null}
                              </ColorsDescription>
                            </Product>
                          )
                        }
                      }
                    )
                  : null}
              </ProductsWrapperMobile>
            </GeneralGridMobile>

            {productDisplay[1] ? (
              <ProductTypeTitleMobileOther className="fade-in">
                {changeName(productDisplay[1])}
              </ProductTypeTitleMobileOther>
            ) : null}
            <GeneralGridMobile className="fade-in">
              {productDisplay[1] ? (
                <ProductsWrapperMobile>
                  {allShopifyProduct.edges
                    ? allShopifyProduct.edges.map(
                        ({
                          node: {
                            id,
                            handle,
                            title,
                            productType,
                            images: [firstImage],
                            variants: [firstVariant],
                            options,
                          },
                        }) => {
                          if (productType === productDisplay[1]) {
                            return (
                              <Product
                                key={id}
                                onClick={e => {
                                  // To stop the page reloading
                                  e.preventDefault()
                                  // Lets track that custom click
                                  trackCustomEvent({
                                    // string - required - The object that was interacted with (e.g.video)
                                    category: 'Mobile | Click on Product',
                                    // string - required - Type of interaction (e.g. 'play')
                                    action: 'Click',
                                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                    label: 'F/W 2020 Shop',
                                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                    value: handle,
                                  })
                                }}
                              >
                                <Link to={`/product/${handle}/`}>
                                  {firstImage && firstImage.localFile && (
                                    <Img
                                      style={{ objectFit: 'contain' }}
                                      fluid={
                                        firstImage.localFile.childImageSharp
                                          .fluid
                                      }
                                      alt={handle}
                                    />
                                  )}
                                </Link>
                                <ProductsDescription>
                                  <Title>{title}</Title>
                                  <PriceTag>
                                    USD${getPrice(firstVariant.price)}
                                  </PriceTag>
                                </ProductsDescription>
                                <ColorsDescription>
                                  {options[1] !== undefined
                                    ? options[1].values.map(item => (
                                        <div
                                          className="hover-effect"
                                          style={{
                                            background: '#' + item,

                                            borderRadius: '50%',
                                            width: '18px',
                                            height: '18px',
                                            margin: '0 10px 0 0',
                                            border: '0.5px solid black',
                                          }}
                                        ></div>
                                      ))
                                    : null}
                                </ColorsDescription>
                              </Product>
                            )
                          }
                        }
                      )
                    : null}
                </ProductsWrapperMobile>
              ) : null}
            </GeneralGridMobile>

            {productDisplay[2] ? (
              <ProductTypeTitleMobileOther>
                {changeName(productDisplay[2])}
              </ProductTypeTitleMobileOther>
            ) : null}
            <GeneralGridMobile>
              {productDisplay[2] ? (
                <ProductsWrapperMobile>
                  {allShopifyProduct.edges
                    ? allShopifyProduct.edges.map(
                        ({
                          node: {
                            id,
                            handle,
                            title,
                            productType,
                            images: [firstImage],
                            variants: [firstVariant],
                            options,
                          },
                        }) => {
                          if (productType === productDisplay[2]) {
                            return (
                              <Product
                                key={id}
                                onClick={e => {
                                  // To stop the page reloading
                                  e.preventDefault()
                                  // Lets track that custom click
                                  trackCustomEvent({
                                    // string - required - The object that was interacted with (e.g.video)
                                    category: 'Mobile | Click on Product',
                                    // string - required - Type of interaction (e.g. 'play')
                                    action: 'Click',
                                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                    label: 'F/W 2020 Shop',
                                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                    value: handle,
                                  })
                                }}
                              >
                                <Link to={`/product/${handle}/`}>
                                  {firstImage && firstImage.localFile && (
                                    <Img
                                      style={{ objectFit: 'contain' }}
                                      fluid={
                                        firstImage.localFile.childImageSharp
                                          .fluid
                                      }
                                      alt={handle}
                                    />
                                  )}
                                </Link>
                                <ProductsDescription>
                                  <Title>{title}</Title>
                                  <PriceTag>
                                    USD${getPrice(firstVariant.price)}
                                  </PriceTag>
                                </ProductsDescription>
                                <ColorsDescription>
                                  {options[1] !== undefined
                                    ? options[1].values.map(item => (
                                        <div
                                          className="hover-effect"
                                          style={{
                                            background: '#' + item,

                                            borderRadius: '50%',
                                            width: '18px',
                                            height: '18px',
                                            margin: '0 10px 0 0',
                                            border: '0.5px solid black',
                                          }}
                                        ></div>
                                      ))
                                    : null}
                                </ColorsDescription>
                              </Product>
                            )
                          }
                        }
                      )
                    : null}
                </ProductsWrapperMobile>
              ) : null}
            </GeneralGridMobile>

            {productDisplay[3] ? (
              <ProductTypeTitleMobileOther>
                {changeName(productDisplay[3])}
              </ProductTypeTitleMobileOther>
            ) : null}
            <GeneralGridMobile>
              {productDisplay[3] ? (
                <ProductsWrapperMobile>
                  {allShopifyProduct.edges
                    ? allShopifyProduct.edges.map(
                        ({
                          node: {
                            id,
                            handle,
                            title,
                            productType,
                            images: [firstImage],
                            variants: [firstVariant],
                            options,
                          },
                        }) => {
                          if (productType === productDisplay[3]) {
                            return (
                              <Product
                                key={id}
                                onClick={e => {
                                  // To stop the page reloading
                                  e.preventDefault()
                                  // Lets track that custom click
                                  trackCustomEvent({
                                    // string - required - The object that was interacted with (e.g.video)
                                    category: 'Mobile | Click on Product',
                                    // string - required - Type of interaction (e.g. 'play')
                                    action: 'Click',
                                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                    label: 'F/W 2020 Shop',
                                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                    value: handle,
                                  })
                                }}
                              >
                                <Link to={`/product/${handle}/`}>
                                  {firstImage && firstImage.localFile && (
                                    <Img
                                      style={{ objectFit: 'contain' }}
                                      fluid={
                                        firstImage.localFile.childImageSharp
                                          .fluid
                                      }
                                      alt={handle}
                                    />
                                  )}
                                </Link>
                                <ProductsDescription>
                                  <Title>{title}</Title>
                                  <PriceTag>
                                    USD${getPrice(firstVariant.price)}
                                  </PriceTag>
                                </ProductsDescription>
                                <ColorsDescription>
                                  {options[1] !== undefined
                                    ? options[1].values.map(item => (
                                        <div
                                          className="hover-effect"
                                          style={{
                                            background: '#' + item,

                                            borderRadius: '50%',
                                            width: '18px',
                                            height: '18px',
                                            margin: '0 10px 0 0',
                                            border: '0.5px solid black',
                                          }}
                                        ></div>
                                      ))
                                    : null}
                                </ColorsDescription>
                              </Product>
                            )
                          }
                        }
                      )
                    : null}
                </ProductsWrapperMobile>
              ) : null}
            </GeneralGridMobile>

            {productDisplay[4] ? (
              <ProductTypeTitleMobileOther>
                {changeName(productDisplay[4])}
              </ProductTypeTitleMobileOther>
            ) : null}
            <GeneralGridMobile>
              {productDisplay[4] ? (
                <ProductsWrapperMobile>
                  {allShopifyProduct.edges
                    ? allShopifyProduct.edges.map(
                        ({
                          node: {
                            id,
                            handle,
                            title,
                            productType,
                            images: [firstImage],
                            variants: [firstVariant],
                            options,
                          },
                        }) => {
                          if (productType === productDisplay[4]) {
                            return (
                              <Product
                                key={id}
                                onClick={e => {
                                  // To stop the page reloading
                                  e.preventDefault()
                                  // Lets track that custom click
                                  trackCustomEvent({
                                    // string - required - The object that was interacted with (e.g.video)
                                    category: 'Mobile | Click on Product',
                                    // string - required - Type of interaction (e.g. 'play')
                                    action: 'Click',
                                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                    label: 'F/W 2020 Shop',
                                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                    value: handle,
                                  })
                                }}
                              >
                                <Link to={`/product/${handle}/`}>
                                  {firstImage && firstImage.localFile && (
                                    <Img
                                      style={{ objectFit: 'contain' }}
                                      fluid={
                                        firstImage.localFile.childImageSharp
                                          .fluid
                                      }
                                      alt={handle}
                                    />
                                  )}
                                </Link>
                                <ProductsDescription>
                                  <Title>{title}</Title>
                                  <PriceTag>
                                    USD${getPrice(firstVariant.price)}
                                  </PriceTag>
                                </ProductsDescription>
                                <ColorsDescription>
                                  {options[1] !== undefined
                                    ? options[1].values.map(item => (
                                        <div
                                          className="hover-effect"
                                          style={{
                                            background: '#' + item,

                                            borderRadius: '50%',
                                            width: '18px',
                                            height: '18px',
                                            margin: '0 10px 0 0',
                                            border: '0.5px solid black',
                                          }}
                                        ></div>
                                      ))
                                    : null}
                                </ColorsDescription>
                              </Product>
                            )
                          }
                        }
                      )
                    : null}
                </ProductsWrapperMobile>
              ) : null}
            </GeneralGridMobile>

            {productDisplay[5] ? (
              <ProductTypeTitleMobileOther>
                {changeName(productDisplay[5])}
              </ProductTypeTitleMobileOther>
            ) : null}

            <GeneralGridMobile>
              {productDisplay[5] ? (
                <ProductsWrapperMobile>
                  {allShopifyProduct.edges
                    ? allShopifyProduct.edges.map(
                        ({
                          node: {
                            id,
                            handle,
                            title,
                            productType,
                            images: [firstImage],
                            variants: [firstVariant],
                            options,
                          },
                        }) => {
                          if (productType === productDisplay[5]) {
                            return (
                              <Product
                                key={id}
                                onClick={e => {
                                  // To stop the page reloading
                                  e.preventDefault()
                                  // Lets track that custom click
                                  trackCustomEvent({
                                    // string - required - The object that was interacted with (e.g.video)
                                    category: 'Mobile | Click on Product',
                                    // string - required - Type of interaction (e.g. 'play')
                                    action: 'Click',
                                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                    label: 'F/W 2020 Shop',
                                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                    value: handle,
                                  })
                                }}
                              >
                                <Link to={`/product/${handle}/`}>
                                  {firstImage && firstImage.localFile && (
                                    <Img
                                      style={{ objectFit: 'contain' }}
                                      fluid={
                                        firstImage.localFile.childImageSharp
                                          .fluid
                                      }
                                      alt={handle}
                                    />
                                  )}
                                </Link>
                                <ProductsDescription>
                                  <Title>{title}</Title>
                                  <PriceTag>
                                    USD$ USD${getPrice(firstVariant.price)}
                                  </PriceTag>
                                </ProductsDescription>
                                <ColorsDescription>
                                  {options[1] !== undefined
                                    ? options[1].values.map(item => (
                                        <div
                                          className="hover-effect"
                                          style={{
                                            background: '#' + item,

                                            borderRadius: '50%',
                                            width: '18px',
                                            height: '18px',
                                            margin: '0 10px 0 0',
                                            border: '0.5px solid black',
                                          }}
                                        ></div>
                                      ))
                                    : null}
                                </ColorsDescription>
                              </Product>
                            )
                          }
                        }
                      )
                    : null}
                </ProductsWrapperMobile>
              ) : null}
            </GeneralGridMobile>

            {productDisplay[6] ? (
              <ProductTypeTitleMobileOther>
                {changeName(productDisplay[6])}
              </ProductTypeTitleMobileOther>
            ) : null}
            <GeneralGridMobile>
              {productDisplay[6] ? (
                <ProductsWrapperMobile>
                  {allShopifyProduct.edges
                    ? allShopifyProduct.edges.map(
                        ({
                          node: {
                            id,
                            handle,
                            title,
                            productType,
                            images: [firstImage],
                            variants: [firstVariant],
                            options,
                          },
                        }) => {
                          if (productType === productDisplay[6]) {
                            return (
                              <Product
                                key={id}
                                onClick={e => {
                                  // To stop the page reloading
                                  e.preventDefault()
                                  // Lets track that custom click
                                  trackCustomEvent({
                                    // string - required - The object that was interacted with (e.g.video)
                                    category: 'Mobile | Click on Product',
                                    // string - required - Type of interaction (e.g. 'play')
                                    action: 'Click',
                                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                    label: 'F/W 2020 Shop',
                                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                    value: handle,
                                  })
                                }}
                              >
                                <Link to={`/product/${handle}/`}>
                                  {firstImage && firstImage.localFile && (
                                    <Img
                                      style={{ objectFit: 'contain' }}
                                      fluid={
                                        firstImage.localFile.childImageSharp
                                          .fluid
                                      }
                                      alt={handle}
                                    />
                                  )}
                                </Link>
                                <ProductsDescription>
                                  <Title>{title}</Title>
                                  <PriceTag>
                                    USD$ USD${getPrice(firstVariant.price)}
                                  </PriceTag>
                                </ProductsDescription>
                                <ColorsDescription>
                                  {options[1] !== undefined
                                    ? options[1].values.map(item => (
                                        <div
                                          className="hover-effect"
                                          style={{
                                            background: '#' + item,

                                            borderRadius: '50%',
                                            width: '18px',
                                            height: '18px',
                                            margin: '0 10px 0 0',
                                            border: '0.5px solid black',
                                          }}
                                        ></div>
                                      ))
                                    : null}
                                </ColorsDescription>
                              </Product>
                            )
                          }
                        }
                      )
                    : null}
                </ProductsWrapperMobile>
              ) : null}
            </GeneralGridMobile>

            {productDisplay[7] ? (
              <ProductTypeTitleMobileOther>
                {changeName(productDisplay[7])}
              </ProductTypeTitleMobileOther>
            ) : null}
            <GeneralGridMobile>
              {productDisplay[7] ? (
                <ProductsWrapperMobile>
                  {allShopifyProduct.edges
                    ? allShopifyProduct.edges.map(
                        ({
                          node: {
                            id,
                            handle,
                            title,
                            productType,
                            images: [firstImage],
                            variants: [firstVariant],
                            options,
                          },
                        }) => {
                          if (productType === productDisplay[7]) {
                            return (
                              <Product
                                key={id}
                                onClick={e => {
                                  // To stop the page reloading
                                  e.preventDefault()
                                  // Lets track that custom click
                                  trackCustomEvent({
                                    // string - required - The object that was interacted with (e.g.video)
                                    category: 'Mobile | Click on Product',
                                    // string - required - Type of interaction (e.g. 'play')
                                    action: 'Click',
                                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                    label: 'F/W 2020 Shop',
                                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                    value: handle,
                                  })
                                }}
                              >
                                <Link to={`/product/${handle}/`}>
                                  {firstImage && firstImage.localFile && (
                                    <Img
                                      style={{ objectFit: 'contain' }}
                                      fluid={
                                        firstImage.localFile.childImageSharp
                                          .fluid
                                      }
                                      alt={handle}
                                    />
                                  )}
                                </Link>
                                <ProductsDescription>
                                  <Title>{title}</Title>
                                  <PriceTag>
                                    USD${getPrice(firstVariant.price)}
                                  </PriceTag>
                                </ProductsDescription>
                                <ColorsDescription>
                                  {options[1] !== undefined
                                    ? options[1].values.map(item => (
                                        <div
                                          className="hover-effect"
                                          style={{
                                            background: '#' + item,

                                            borderRadius: '50%',
                                            width: '18px',
                                            height: '18px',
                                            margin: '0 10px 0 0',
                                            border: '0.5px solid black',
                                          }}
                                        ></div>
                                      ))
                                    : null}
                                </ColorsDescription>
                              </Product>
                            )
                          }
                        }
                      )
                    : null}
                </ProductsWrapperMobile>
              ) : null}
            </GeneralGridMobile>
          </MobileView>
        </div>
      ) : null}
      {/* </ErrorBoundary> */}
    </>
  )
}

export default ProductType
