import styled from '@emotion/styled'
import Image from 'gatsby-image'

export const ProductTypeTitle = styled.div`
  grid-column-start: 3;
  grid-column-end: 5;
  height: 84px;
  font-family: 'BDST';
  letter-spacing: 0.05em;
  font-size: 30px;
`

export const ProductTypeTitleOther = styled.div`
  grid-column-start: 3;
  grid-column-end: 5;
  height: 84px;
  font-family: 'BDST';
  letter-spacing: 0.05em;
  font-size: 30px;
  margin: 84px 0 0 0;
`

export const ProductsWrapper = styled.div`
  grid-column-start: 3;
  grid-column-end: 8;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  z-index: 0;
`

export const Product = styled.div`
  flex-basis: 30%;
  margin: 0 12px 42px 0;
`
export const Img = styled(Image)`
  object-fit: contain !important;
  object-position: center;
  width: 100%;
  max-height: 100%;
`

export const Title = styled.p`
  font-weight: 300;
  font-size: 12px;
  text-align: left;
  text-decoration: none;
  border-bottom: none;
  color: black;
  font-family: 'LTUnivers-BasicRegular';
`

export const PriceTag = styled.span`
  font-weight: 300;
  font-size: 12px;
  text-align: left;
  text-decoration: none;
  border-bottom: none;
  color: black;
  font-family: 'LTUnivers-BasicRegular';
  margin: 2px 0 0 0;
`

export const ProductsDescription = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'LTUnivers-BasicRegular';
`

export const ColorsDescription = styled.div`
  display: flex;
  justify-content: flex-start;
`
export const ProductTypeTitleMobile = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`
export const ProductTypeTitleMobileOther = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  margin: 42px 0 0 12px;
  font-family: 'LTUnivers-BasicRegular';
  font-size: 12px;
`

export const ProductsWrapperMobile = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`

export const ResultLength = styled.div`
  grid-column-start: 5;
  grid-column-end: 6;
  height: 84px;
  font-family: 'BDST';
  letter-spacing: 0.05em;
  font-size: 30px;
`
