import React from 'react'

export const changeName = value => {
  switch (value) {
    case 'women_jackets_coats':
      return 'Women / Jackets / Coats'
      break
    case 'women_outerwear':
      return 'Women / Outerwear'
      break
    case 'women_pants':
      return 'Women / Pants'
      break
    case 'women_denim':
      return 'Women / Denim'
      break
    case 'women_tops':
      return 'Women / Tops'
      break
    case 'women_tshirts':
      return 'Women / T-shirts'
      break
    case 'women_knitwear':
      return 'Women / Knitwear'
      break
    case 'women_hoodie':
      return 'Women / Hoodies'
      break
    case 'women_crewneck':
      return 'Women / Crewneck'
      break
    case 'women_facemask':
      return 'Women / Face Mask'
      break
    case 'women_dresses':
      return 'Women / Dresses'
      break
    case 'women_tshirts':
      return 'Women / Tshirts'
      break
    case 'women_shoes':
      return 'Women / Shoes'
      break
    case 'women_accessories':
      return 'Women / Accessories'
      break
    case 'men_jackets':
      return 'Men / Jackets'
      break
    case 'men_pants':
      return 'Men / Pants'
      break
    case 'men_tshirts':
      return 'Men / T-shirts'
      break
    case 'men_shoes':
      return 'Men / Shoes'
      break
    case 'men_accessories':
      return 'Men / Accessories'
      break
    case 'men_face_mask':
      return 'Men / Face Mask'
      break
    default:
  }
}
